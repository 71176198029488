<template>
    <div>
    <v-app>
        <div class="row">
            <div class="col">
                <v-card>
                    <v-card-title>
                        Cargar DTE(s)
                        <v-spacer></v-spacer>
                        <v-btn class="" @click="fetchData"  text icon color="blue">
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-card outlined tile elevation="1">
                            <v-card-text style="padding-bottom: 0px;">
                                <div class="d-flex justify-space-between mb-4">
                                    <v-text-field v-model="search" clearable label="Buscar" hide-details dense solo class="mr-2" ></v-text-field>
                                    <v-btn class="btn-starkoms-primary" dark style="min-width: 138px;"  >Buscar</v-btn>
                                </div>
                                <div class="d-flex justify-space-between">
                                    <div class="d-flex flex-column">
                                        <span class="text-h6 font-weight-regular black--text"><i class="icon-filter_calendar"></i> Filtro por rango de fecha</span>
                                        <v-menu
                                            ref="menu1"
                                            v-model="menu1"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="290px"
                                            style="border-bottom: 1px solid #000;"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field v-bind="attrs" v-on="on" placeholder="Todos" clearable @click:clear="limpiarRango" v-model="dateRangeText" solo readonly ></v-text-field>
                                            </template>
                                            <v-date-picker id="datepicker-starkoms" @input="formatearRango" range v-model="dates" ></v-date-picker>
                                        </v-menu>
                                    </div>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-card-text>
                    <v-card-text>
                        <h3 class="title">Seleccione el(los) pedido(s) que desea generar dte: {{selected.length ? (selected.length > 1 ? '('+selected.length+') Seleccionados' : '(1) Seleccionado'):''}}</h3>
                        <v-data-table
                            :headers="headers"
                            :items="table"
                            show-select
                            :search="search"
                            v-model="selected"
                            no-results-text="No hay resultados disponibles"
                            no-data-text="No hay datos disponibles"
                            loading-text="Cargando datos..."
                            class="elevation-1 datatable"
                            sort-by="id"
                            :sort-desc="true"
                        >
                            <template v-slot:item.created_at="{ item }">
                                <td class="text-center">
                                    {{ $moment(item.created_at).format('MMMM Do YYYY, h:mm:ss a') }}
                                </td>
                            </template>
                            <template v-slot:item.fuente="{ item }">
                                <td class="text-center">
                                    {{ item.source ? item.source.name : 'Sin asignar' }}
                                </td>
                            </template>
                            <template v-slot:item.estado="{ item }">
                                <td class="d-flex flex-column">
                                    <button
                                    :class="'btn btn-sm btn-'+item.order_state.colour"
                                    v-text="item.order_state.name"
                                    ></button>
                                </td>
                            </template>
                        </v-data-table>
                        <br><br>
                        <v-btn @click="openModalCargarDtes" v-show="selected.length" small color="success" dark class="float-left"
                        ><v-icon left dark>mdi-format-list-checks</v-icon> Cargar DTE{{selected.length > 1 ? 's':''}}</v-btn>
                    </v-card-text>
                </v-card>
            </div>
        </div>
    </v-app>
    <modal-cargar-dtes @success="fetchData"></modal-cargar-dtes>
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import format from 'date-fns/format';
import ModalCargarDtes from '@/views/oms/Envios/ModalCargarDtes.vue';

export default {
    components:{
        ModalCargarDtes
    },
    data(){
        return {
            dates: [],
            menu1: false,
            dateRangeText: "",
            search: '',
            table: [],
            selected: [],
            couriersList: [],
        }
    },
    mounted(){
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Cargar dte(s)", route: "/envios/cargar-dte" },
        ]);
        this.fetchData();
    },
    methods: {
        formatDates(dateOne, dateTwo) {
            let formattedDates = ''
            if (dateOne) {
                formattedDates = format(dateOne, this.dateFormat)
            }
            if (dateTwo) {
                formattedDates += ' - ' + format(dateTwo, this.dateFormat)
            }
            return formattedDates
        },
        filtroRangoFechas(value) {
            if (!this.dateRangeText || !this.dates.length) {
                return true;
            } else {
                if ( this.$moment(value).isSameOrAfter(this.$moment(this.dates[0])) && this.$moment(value).isSameOrBefore(this.$moment(this.dates[1]))) {
                    return true;
                } else {
                    return false;
                }
            }
        },
        formatearRango() {
            if (!this.dates.length) {
                this.dateRangeText = "";
            } else {
                this.dateRangeText = this.dates.join(" ~ ");
            }
        },
        limpiarRango() {
            this.dates = [];
        },
        fetchData(){
            var vm = this;
            this.axios({
                url: 'orders/without_billing',
                method: 'POST',
            }).then( response => {
                vm.table = response.data.data;
            }).catch( error => {
                console.log(error);
            });
        },
        
        openModalCargarDtes()
        {
            this.$emit('openModalCargarDtes', this.selected);
            this.selected = [];
        }
    },
    computed: {
        headers(){ 
            return [
                { text: '#...',  align: 'left',  sortable: true, value: 'id'},
                { text: 'Referencia', value: 'order_reference' },
                { text: 'Fecha Ingreso', value: 'created_at', filter: (v) => this.filtroRangoFechas(v) },
                { text: 'Estado', value: 'estado' },
                { text: 'Fuente', value: 'fuente' },
            ]
        }
    }
}
</script>